﻿:root {
    --color--black: #000000;
    --color--white: #ffffff;
    --color--normal-fg: var(--color--brand-charcoal);
    --color--normal-bg: #ffffff;
    --color--warning-fg: var(--color--brand-charcoal);
    --color--warning-bg: #ffe599;
    --color--alert-fg: #ffffff;
    --color--alert-bg: #ff9999;
    --color--success: #48bb78;
    --color--canvas: #f0f0f0;
    --color--canvas--darker: #e3e3e3;
    --color--canvas--xdarker: #cbcbcb;
    --color--tile: #fff;
    --color--brand-red--lighter: #f27067;
    --color--brand-red: #ed3426;
    --color--brand-charcoal-lighter: #717171;
    --color--brand-charcoal: #4e4e4e;
    --color--brand-charcoal-darker: #3a3a3a;
    --color--brand-charcoal-xdark: #2e2e2e;
    --color--brand-mid-blue: #bfcaea;
    --color--brand-vlight-blue: #e8ecf9;
}

.theme {
    &--pdi {
        color: var(--color--brand-charcoal-darker) !important;
        background-color: var(--color--brand-vlight-blue) !important;
    }
}
