﻿/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: rems(80px);
    height: rems(34px);
    /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked + .slider {
        background-color: var(--color--brand-red);

        &:before {
            opacity: 0.9;
        }
    }

    input:focus + .slider {
        box-shadow: 0 0 1px var(--color--brand-red);
    }

    input:checked + .slider:before {
        transform: translateX(#{rems(46px)});
    }
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color--canvas--darker);
    -webkit-transition: .4s;
    transition: .4s;
    text-align: center;
    font-size: var(--font-size--sm);
    line-height: rems(32px);
    color: var(--color--brand-charcoal-darker);

    &:before {
        position: absolute;
        content: "";
        height: rems(26px);
        width: rems(26px);
        left: rems(4px);
        bottom: rems(4px);
        background-color: var(--color--white);
        -webkit-transition: .4s;
        transition: .4s;
        opacity: 0.5;
    }

    &:hover:before {
        opacity: 1;
    }

    &.round {
        border-radius: rems(34px);

        &:before {
            border-radius: 50%;
        }
    }
}
