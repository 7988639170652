.state-info {
    position: absolute;
    top: 0;
    right: 0;
    width: rems(20px);
    height: rems(20px);
    overflow: hidden;
    cursor: pointer;

    &__desc {
        display: none;
    }

    .arrow {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 0;
        height: 0;
        cursor: pointer;
        border-style: solid;
        border-width: rems(10px);
        border-color: var(--color--brand-red) var(--color--brand-red) transparent transparent;
    }

    &:hover {
        background: rgba(255,255,255,0.8);
        color: var(--color--black);
        padding: 0 rems(10px);
        font-size: var(--font-size--sm);
        line-height: var(--font-size-lh--sm);
        box-shadow: 0px 4px 5px -1px rgba(0,0,0,0.25);
        overflow: visible;
        width: 80%;
        height: auto;

        .state-info__desc {
            display: block;
        }
    }
}
