﻿.icon {
    display: inline-block;
    width: rems(32px);
    height: rems(32px);
    cursor: pointer;
    background-position: center center;
    background-size: cover;

    &--pending-actions--charcoal {
        background-image: url('images/icons/pending-actions--charcoal.svg');
    }

    &--service-red {
        background-image: url('images/icons/service--red.svg');
    }

    &--transport-red {
        background-image: url('images/icons/transport--red.svg');
    }

    &--refresh--charcoal {
        background-image: url('images/icons/refresh--charcoal.svg');
    }

    &--error-white {
        background-image: url('images/icons/warning--white.svg');
    }

    &--logout-red {
        background-image: url('images/icons/logout--red.svg');
    }

    &--login-red {
        background-image: url('images/icons/login--red.svg');
    }
}
