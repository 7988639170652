﻿.tile {
    position: relative;
    color: var(--color--brand-charcoal);
    background-color: var(--color--tile);
    min-height: rems(40px);
    border-radius: rems(5px);
    border: solid 3px var(--color--canvas--darker);
    box-shadow: 0px 0px 5px 0px #e8e8e8;
    display: flex;
    flex-direction: column;

    &--center {
        justify-content: center;
        text-align: center;
    }

    &--pad {
        padding: rems(30px) rems(20px);
    }

    &--transparent {
        background-color: transparent;
        border: 0;
        outline: 0;
    }

    &__head {
        text-align: center;
        color: var(--color--white);
        background-color: var(--color--brand-charcoal-darker);
        padding: rems(10px) 0;
    }


    &__data {
        display: flex;
        flex-direction: row;
        font-size: var(--font-size--lg);
        line-height: var(--font-size--lg);

        .data__option {
            text-align: center;
            cursor: pointer;
            margin-right: 5px;
            flex-basis: 50%;
            padding: rems(4px);
            font-size: var(--font-size);
            line-height: var(--font-size);

            &:last-child {
                margin-right: 0;
            }

            .title, .option {
                display: block;
            }

            .title {
                margin: 0;
                padding: 0;
                text-transform: uppercase;
                font-size: var(--font-size--sm);
                line-height: var(--font-size--sm);
                margin-bottom: rems(5px);
            }

            .data {
                font-size: var(--font-size--xxl);
                line-height: var(--font-size--xxl);

                &.alert {
                    color: var(--color--brand-red);
                }
            }
        }
    }
}
