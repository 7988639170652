﻿.selected-depot {
    margin-bottom: rems(10px);

    .input {
        display: block;
        width: 100%;
    }

    .label {
        display: inline-block;
        font-size: rems(16px);
        font-weight: var(--font-weight);
        margin-bottom: rems(5px);
        color: var(--color--brand-charcoal-darker);
    }
}
