﻿.refresh {
    $self: ".refresh";
    position: relative;
    height: rems(34px);
    background-color: var(--color--canvas--darker);
    text-align: center;
    cursor: pointer;

    &:hover {
        #{ $self }__bar {
            background-color: var(--color--canvas--darker);
            transition: background-color 0.3s ease-in-out;
        }
    }

    .icon, &__bar {
        position: absolute;
        display: block;
        height: 100%;
        text-align: center;
        top: 0;
        left: 0;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .icon {
        width: 100%;
        bottom: 0;
        right: 0;
        z-index: 3;
    }

    &__bar {
        height: 100%;
        width:0%;
        background-color: var(--color--brand-red--lighter);
        transition: background-color 0.3s ease-in-out;
    }
}
