﻿.nav {
    background-color: var(--color--brand-charcoal);
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 5px 0px var(--color--brand-charcoal-darker);

    &__logo {
        background-color: var(--color--brand-charcoal-darker);
        padding: rems(10px) rems(5px) 0 rems(5px);
    }

    &__options {
        padding: rems(10px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 2;

        @include tablet {
            justify-content: flex-start;
        }
    }

    .option {
        display: inline-block;
        cursor: pointer;
        border: none;
        outline: 0;
        background-color: var(--color--white);
        color: var(--color--brand-red);
        text-transform: uppercase;
        font-size: var(--font-size);
        line-height: var(--font-size-lh);
        font-weight: var(--font-weight--bold);
        padding: rems(5px) rems(20px);
        border-radius: rems(20px);
        border-bottom: solid 1px var(--color--brand-charcoal-darker);
        box-shadow: 0px 0px 5px 0px var(--color--brand-charcoal-darker);
        transition: opacity 0.2s ease-in-out;
        opacity: 0.5;

        &.active, &:hover {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }

        .text {
            display: none;
        }

        @include tablet {
            margin: 0 rems(5px);

            .text {
                display: inline-block;
            }
        }

        .icon, .text {
            vertical-align: middle;
        }

        .icon {
            margin-right: rems(5px);
        }
    }
}
