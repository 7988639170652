﻿html, body {
    font-size: 16px;
    font-size: 80%;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    @include tablet {
        font-size: 90%;
    }

    @include desktop {
        font-size: 100%;
    }
}

div {
    box-sizing: border-box;
}

.hide {
    display: none;
}

.tb, .dt, .dt-l, .dt-xl {
    display: none;
}

.mb {
    display: inline-block;
}

@include tablet {
    // Dont change precidence below
    .mb {
        display: none;
    }

    .tb {
        display: inline-block;
    }
}

@include desktop {
    // Dont change precidence below
    .mb, .tb {
        display: none;
    }

    .dt {
        display: inline-block;
    }
}

@include desktop-l {
    // Dont change precidence below
    .mb, .tb, .dt {
        display: none;
    }

    .dt-l {
        display: inline-block;
    }
}

@include desktop-xl {
    // Dont change precidence below
    .mb, .tb, .dt, .dt-l {
        display: none;
    }

    .dt-xl {
        display: inline-block;
    }
}
