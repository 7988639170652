.splash {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(2px);

    &--error {
        z-index: 3;

        .error {
            margin-top: -25vh;
            background-color: var(--color--brand-charcoal);
            color: var(--color--white);
            border-radius: rems(15px);
            box-shadow: 0px 4px 5px -1px rgba(0,0,0,0.25);
            padding: rems(20px);
            text-align: center;
            border: solid 2px var(--color--brand-charcoal-darker);

            @include desktop {
                padding: rems(20px) rems(40px);
            }

            .error {
                &__title {
                    font-size: var(--font-size--md);
                    line-height: var(--font-size--md);
                    font-weight: var(--font-weight);
                    margin-bottom: rems(10px);
                }

                &__text {
                    font-size: var(--font-size);
                    line-height: var(--font-size-lh);
                    font-weight: var(--font-weight--light);
                    margin-bottom: rems(20px);
                }

                &__btns {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    &--updating {
        z-index: 2;

        .loader {
            width: 40px;
            aspect-ratio: 1;
            animation: l3-1 2s infinite linear, l3-2 3s infinite steps(1) -.5s;
        }
    }
}

/* HTML: <div class="loader"></div> */


@keyframes l3-1 {
    0% {
        transform: perspective(150px) rotateX( 0deg) rotateY(0deg)
    }

    50% {
        transform: perspective(150px) rotateX(180deg) rotateY(0deg)
    }

    100% {
        transform: perspective(150px) rotateX(180deg) rotateY(180deg)
    }
}

@keyframes l3-2 {
    0% {
        background: var(--color--brand-red);
    }

    33% {
        background: var(--color--brand-charcoal);
    }

    66% {
        background: var(--color--brand-mid-blue);
    }
}
