﻿.input {
    &--date {
        border: none;
        outline: none;
        padding: rems(5px) rems(10px);
        border-radius: rems(10px);
        color: var(--color--charcoal--darker);

        &:not(:focus) {
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
        }
    }

    &--select {
        border: none;
        outline: none;
        padding: rems(10px);
        border-bottom: solid 2px #cad5e2;
        color: var(--color--charcoal--darker);
    }
}
