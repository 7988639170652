﻿.calendar {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: rems(10px);
    border: solid rems(10px) var(--color--canvas--darker);
    color: var(--color--brand-charcoal);
    background-color: var(--color--white);
    text-align: center;
    box-shadow: 0px 0px 5px 0px #e8e8e8;

    .hanger {
        width: rems(10px);
        height: rems(30px);
        position: absolute;
        top: rems(-20px);
        left: rems(20px);
        background-color: var(--color--canvas--xdarker);

        &--r {
            left: initial;
            right: rems(20px);
        }
    }

    &__date-time {
        font-weight: var(--font-weight--bold);
        text-align: center;
        padding: rems(20px) 0;

        .date, .time {
            display: block;
        }

        .date {
            margin-bottom: rems(10px);
        }

        .time {
            font-size: var(--font-size--xl);
            line-height: var(--font-size--xl);
        }
    }

    .hr {
        background-color: var(--color--canvas--darker);
        height: rems(6px);
        border-radius: rems(3px);
        width: 60%;
        margin: 0 auto 0 auto;
    }

    .look-ahead, &__days {
        padding: rems(5px);
    }


    .look-ahead {
        text-align: center;

        .input--date {
            background-color: whitesmoke;
        }
    }

    &__days {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .days__day {
            text-align: center;
            cursor: pointer;
            color: var(--color--white);
            background-color: var(--color--brand-charcoal);
            margin-right: 2px;
            flex-basis: 25%;
            font-size: var(--font-size--sm);
            line-height: var(--font-size--sm);
            padding: rems(2px);
            opacity: 0.5;
            transition: opacity 0.3 ease-in-out;

            &.active, &:hover {
                opacity: 1;
                cursor: pointer;
            }

            &.active {
                border-bottom: solid 4px var(--color--brand-red--lighter);
                padding-bottom: rems(5px);
                margin-top: rems(-5px);
            }

            &:last-child {
                margin-right: 0;
            }

            .name, .date {
                display: block;
                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */
            }

            .name {
                margin: 0;
                padding: 0;
                text-transform: uppercase;
                margin-bottom: rems(5px);
            }

            .date {
            }
        }
    }
}
