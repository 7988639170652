﻿:root {
    --font: Roboto,Arial,sans-serif;
    --font-size--xs: #{rems(10px)};
    --font-size--sm: #{rems(12px)};
    --font-size: #{rems(16px)};
    --font-size-lh: #{line-height(16px)};
    --font-size--md: #{rems(18px)};
    --font-size--lg: #{rems(24px)};
    --font-size--lg-lh: #{line-height(24px)};
    --font-size--xl: #{rems(30px)};
    --font-size--xl-lh: #{line-height(30px)};
    --font-size--xxl: #{rems(40px)};
    --font-size--xxl-lh: #{line-height(40px)};
    --font-size--xxxl: #{rems(50px)};
    --font-weight--light: 200;
    --font-weight: 400;
    --font-weight--bold: 700;
    --font-icon: #{rems(32px)};
}

b, strong, .bold {
    font-weight: var(--font-weight--bold);
}

italic {
    font-style: italic;
}

body, p, a, button {
    font-family: var(--font);
}

.dull {
    opacity: 0.75;
}
