﻿@mixin mobile-m {
    @media (min-width: #{$breakpoint-mobile}) {
        @content;
    }
}

@mixin mobile-l {
    @media (min-width: #{$breakpoint-mobile-l}) {
        @content;
    }
}

@mixin mobile-med {
    @media (min-width: #{$breakpoint-mobile-xl}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$breakpoint-tablet}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$breakpoint-desktop}) {
        @content;
    }
}

@mixin desktop-l {
    @media (min-width: #{$breakpoint-desktop-l}) {
        @content;
    }
}

@mixin desktop-xl {
    @media (min-width: #{$breakpoint-desktop-xl}) {
        @content;
    }
}