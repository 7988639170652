﻿.machine {
    $self: ".machine";
    opacity: 0.5;

    &.active {
        opacity: 1;
    }

    &__head {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: end;
        padding: rems(50px) 0;

        .name {
            text-transform: uppercase;
            font-size: var(--font-size--xl);
            font-weight: var(--font-weight--bold);
            margin-right: rems(20px);

            @include tablet {
                font-size: var(--font-size--xxl);
            }

            @include desktop {
                font-size: var(--font-size--xxl);
            }
        }

        .data, .text {
            display: block;
            text-align: center;
        }

        .data {
            font-size: var(--font-size--xxl);
            line-height: var(--font-size--xxl);

            @include tablet {
                font-size: var(--font-size--xxxl);
                line-height: var(--font-size--xxxl);
            }

            @include desktop {
                font-size: var(--font-size--xxxl);
                line-height: var(--font-size--xxxl);
            }

            &.active {
                color: var(--color--brand-red);
            }
        }

        .text {
            font-size: var(--font-size--xs);
        }
    }

    &__status {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: rems(5px);

        &:hover {
            .status__nav {
                opacity: 1;
                height: rems(50px);
                transition: all 0.3s ease-in-out;
                margin-top: rems(-50px);
            }
        }

        .status__nav {
            position: absolute;
            top: 3px;
            left: 0;
            right: 0;
            bottom: 0;
            margin-top: 0;
            height: 1px;
            width: 100%;
            padding: 0 rems(5px);
            opacity: 0;
            transition: all 0.3s ease-in-out;

            .nav__sub {
                width: 100%;
                height: 100%;
                background: rgba(58, 58, 58, 0.75);
                color: var(--color--white);

                span {
                    vertical-align: middle;
                    display: block;
                    text-align: center;
                }
            }
        }

        .status__option {
            position: relative;
            text-align: center;
            cursor: pointer;
            color: var(--color--white);
            background-color: var(--color--brand-charcoal);
            margin-right: 5px;
            flex-basis: 25%;
            padding: rems(4px);
            font-size: var(--font-size);
            line-height: var(--font-size);

            &:hover {
                background: rgba(58, 58, 58, 0.75);
                margin-top: rems(-2px);
            }

            &:last-child {
                margin-right: 0;
            }

            .title {
                display: block;
            }

            .title {
                margin: 0;
                padding: 0;
                text-transform: uppercase;
                font-size: var(--font-size--sm);
                line-height: var(--font-size--sm);
                margin-bottom: rems(5px);
            }

            .data {
                font-size: var(--font-size--md);
                line-height: var(--font-size--md);
            }
        }
    }
}
