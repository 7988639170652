.state {
    &--normal {
        color: var(--color--normal-fg);
        background-color: var(--color--normal-bg);
    }

    &--warning {
        color: var(--color--warning-fg);
        background-color: var(--color--warning-bg);
    }

    &--alert {
        color: var(--color--alert-fg);
        background-color: var(--color--alert-bg);
    }
}