﻿.blades {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include tablet {
        flex-direction: row;
    }

    .blade {
        padding: rems(10px) rems(5px);

        @include tablet {
            padding: rems(10px) 0;
            height: 100%;
        }

        @include desktop {
            padding: rems(10px);
        }

        &--1 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include tablet {
                padding-right: rems(10px);
            }

            .tile {
                margin-bottom: 5%;
            }

            @include tablet {
                width: 25%;
            }

            @include desktop-l {
                width: 20%;
            }

            @include desktop-xl {
                width: 18%;
            }
        }

        &--2 {
            position: relative;
            width: 100%;

            @include tablet {
                width: 75%;
            }

            @include desktop-l {
                width: 80%;
            }

            @include desktop-xl {
                width: 82%;
            }

            .blade__sub {
                display: flex;
                flex-direction: column;
                height: 100%;
                border: solid #ffffff rems(10px);

                .head {
                    padding: 0 rems(5px);
                    padding-bottom: rems(10px);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: var(--color--white);
                    background-color: var(--color--brand-charcoal-darker);
                    box-shadow: 0px 4px 5px -1px rgba(0,0,0,0.25);

                    @include tablet {
                        flex-direction: row;
                        padding-bottom: 0;
                    }

                    .title {
                        padding: rems(10px);
                        text-align: center;
                        flex-grow: 2;
                        font-weight: var(--font-weight--bold);

                        .dull {
                            font-weight: var(--font-weight--light);
                        }
                    }

                    .controls {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .toggle {
                            height: 100%;
                            padding: rems(5px) 0;
                            margin-right: rems(10px);
                        }

                        .refresh {
                            width: rems(60px);
                        }
                    }
                }

                .data {
                    flex-grow: 2;

                    .tiles {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        flex-grow: 2;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        height:100%;

                        .tile {
                            margin-bottom: rems(10px);
                            // 2 per row
                            flex-basis: 49%;
                            margin: 0.4%;

                            @media (max-width: 670px) and (orientation: landscape) {
                                // 4 per row
                                flex-basis: 24.5%;
                                margin: 0.2%;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        @include tablet {
                            flex-direction: row;
                            flex-grow: 2;
                            flex-wrap: wrap;
                            align-content: flex-start;

                            .tile {
                                // 3 per row
                                flex-basis: 32.5%;
                                margin: 0.4%;
                            }
                        }

                        @include desktop {
                            .tile {
                                // 3 per row
                                flex-basis: 32.5%;
                                margin: 0.4%;
                            }
                        }

                        @include desktop-l {
                            .tile {
                                // 5 per row
                                flex-basis: 19.5%;
                                margin: 0.2%;
                            }
                        }

                        .no-data {
                            position: absolute;
                            display: none;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background-color: var(--color--canvas);
                            background-image: url('images/icons/pending-actions--charcoal.svg');
                            background-position: center center;
                            background-size: contain;
                            background-repeat: no-repeat;

                            &.active {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &__sub {
            .tile:last-child {
                @include tablet {
                    margin-bottom: 0;
                }
            }
        }
    }
}
